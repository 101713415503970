import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import "../styles/all.scss"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <div className="columns has-margin-top-25 has-margin-bottom-25">
      <div className="column is-6 is-offset-2">
        <h1 className="title">Sorry!</h1>
        <p>
          <strong>We cannot find that page.</strong>
        </p>
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
